import { type ComponentProps } from 'react';
import {
  StaticHomeHero,
  type HomeHeroProps,
} from './StaticHomeHero/StaticHomeHero';
import styles from './HomeHero.module.css';

type StaticHomeHeroProps = ComponentProps<typeof StaticHomeHero>;

export const homeHeroCta = styles.button;

const HEADING_COPY = 'The easiest way to eat healthy';

export function HomeHero(props: HomeHeroProps) {
  const staticHeroProps: StaticHomeHeroProps = {
    ...props,
    heading: HEADING_COPY,
  };

  return <StaticHomeHero {...staticHeroProps} />;
}
