import { type ReactNode } from 'react';
import { Heading, Paragraph } from '@hungryroot/ramen';
import styles from './DigIn.module.css';

type Props = {
  picture: ReactNode;
  resumeQuizButton: ReactNode;
};

export const digInCta = styles.cta;

export function DigIn({ picture, resumeQuizButton }: Props) {
  return (
    <section className={styles.container}>
      <div className={styles.logo}>{picture}</div>
      <Heading variant="HeroAlt">Ready to dig in?</Heading>
      <div className={styles.subheading}>
        <Paragraph variant="lead">
          Sign up today for a{' '}
          <strong className={styles['free-gift']}>
            free gift in every delivery.
          </strong>
        </Paragraph>
        <Paragraph variant="lead">Skip a week or cancel anytime.</Paragraph>
      </div>
      {resumeQuizButton}
    </section>
  );
}
