import { useMedia } from 'react-use';
import { Heading } from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import styles from './WellnessSectionContent.module.css';

type ImageProp = {
  src: string;
  height: number;
  width: number;
  srcSet: string;
};

export type WellnessSectionProps = {
  img1Desktop: ImageProp;
  img1Mobile: ImageProp;
  img2Desktop: ImageProp;
  img2Mobile: ImageProp;
  img3Desktop: ImageProp;
  img3Mobile: ImageProp;
  img4Desktop: ImageProp;
  img4Mobile: ImageProp;
};

export function WellnessSectionContent(props: WellnessSectionProps) {
  const {
    img1Desktop,
    img1Mobile,
    img2Desktop,
    img2Mobile,
    img3Desktop,
    img3Mobile,
    img4Desktop,
    img4Mobile,
  } = props;

  const isDesktop = useMedia(BreakpointQueryLarge, false);

  const br = isDesktop ? <br /> : ' ';

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Heading variant="H4" className={styles.heading}>
          <span className={styles['text-avocado']}>
            Save hours planning, shopping, and cooking.
          </span>{' '}
          <span className={styles['text-transparent']}>
            We'll recommend and deliver food you'll love.
          </span>
        </Heading>
        <picture>
          <source srcSet={img1Desktop.src} media={BreakpointQueryLarge} />
          <img {...img1Mobile} loading="lazy" className={styles.image} alt="" />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant="H4" className={styles.heading}>
          <span className={styles['text-avocado']}>Feel your best</span>{' '}
          <span className={styles['text-transparent']}>
            with nutritious recipes and groceries for every{br}taste, budget,
            and goal.
          </span>
        </Heading>
        <picture>
          <source srcSet={img2Desktop.src} media={BreakpointQueryLarge} />
          <img {...img2Mobile} loading="lazy" className={styles.image} alt="" />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant="H4" className={styles.heading}>
          <span className={styles['text-avocado']}>Discover new favorites</span>{' '}
          <span className={styles['text-transparent']}>
            with 15,000+ recipes, ready-to-eat{br}meals, and healthy groceries.
          </span>
        </Heading>
        <picture>
          <source srcSet={img3Desktop.src} media={BreakpointQueryLarge} />
          <img {...img3Mobile} loading="lazy" className={styles.image} alt="" />
        </picture>
      </div>

      <div className={styles.item}>
        <Heading variant="H4" className={styles.heading}>
          <span className={styles['text-avocado']}>
            Save up to 40% each week
          </span>{' '}
          <span className={styles['text-transparent']}>
            by ditching{br}takeout and reducing food waste.
          </span>
        </Heading>
        <picture>
          <source srcSet={img4Desktop.src} media={BreakpointQueryLarge} />
          <img {...img4Mobile} loading="lazy" className={styles.image} alt="" />
        </picture>
      </div>
    </div>
  );
}
