import { Heading } from '@hungryroot/ramen';
import {
  Variant as HomepageIconVariant,
  HomepageIcon,
} from '../../HomepageIcon';
import {
  WellnessSectionContent,
  type WellnessSectionProps,
} from '../WellnessSectionContent/WellnessSectionContent';
import styles from './WellnessSection.module.css';

export function WellnessSection(props: WellnessSectionProps) {
  return (
    <div className={styles.outer}>
      <section className={styles.container}>
        <div className={styles.heading}>
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <HomepageIcon
            variant={HomepageIconVariant.Leaf}
            className={styles.leaf}
          />
          <Heading variant="HeroAlt" className={styles['heading-text']}>
            Personalized meal plans + groceries
          </Heading>
        </div>
        <WellnessSectionContent {...props} />
      </section>
    </div>
  );
}
